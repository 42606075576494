html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  max-height: 1000000px;
  margin: 0;
  padding: 0;
}

body {
  color: #333;
  background: #fff;
  font: 16px/1.6 "Muli", sans-serif;
  min-width: 320px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Muli", sans-serif;
  font-weight: 400;
  margin: 0 0 20px;
  color: inherit;
}

h1 {
  font-size: 46px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 17px;
}

h6 {
  font-size: 15px;
}

p {
  margin: 0 0 15px;
}

a {
  color: #f64b4b;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover,
a:focus {
  text-decoration: none;
  color: #f41a1a;
}

ul,
.teams-block .image .overlay,
.contact-block .contact-info ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#wrapper {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.container-fluid {
  margin: 0 auto;
  max-width: 1200px;
}

.content-block ul {
  margin: 0 0 15px;
}

.content-block ul li {
  position: relative;
  padding: 0 0 0 20px;
}

.content-block ul li:before {
  content: "\f101";
  font-family: "fontawesome";
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
}

.content-block ul li a {
  color: #333;
}

.content-block ul li a:hover {
  color: #f64b4b;
}

.btn.btn-primary {
  background: #f64b4b;
  border-color: #f64b4b;
  padding: 13px 35px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  text-transform: uppercase;
  transition: background 0.3s linear, color 0.3s linear;
  -webkit-transition: background 0.3s linear, color 0.3s linear;
}

.btn.btn-primary:focus,
.btn.btn-primary:hover {
  background: #fff;
  border-color: #f64b4b;
  color: #f64b4b;
}

.btn.btn-primary .fas {
  margin: 0 0 0 6px;
  font-size: 15px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="search"],
textarea {
  background: #fff;
  border: 1px solid #cccccc;
  margin: 0;
  padding: 5px 15px;
  width: 100%;
  height: 47px;
  color: gray;
  font-size: 16px;
  line-height: 20px;
}

input[type="text"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
input[type="search"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: gray;
}

input[type="text"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="tel"]::-moz-placeholder,
input[type="search"]::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  color: gray;
}

input[type="text"]:-moz-placeholder,
input[type="email"]:-moz-placeholder,
input[type="tel"]:-moz-placeholder,
input[type="search"]:-moz-placeholder,
textarea:-moz-placeholder {
  color: gray;
}

input[type="text"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="search"]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: gray;
}

input[type="text"].placeholder,
input[type="email"].placeholder,
input[type="tel"].placeholder,
input[type="search"].placeholder,
textarea.placeholder {
  color: gray;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="search"]:focus,
textarea:focus {
  outline: none;
}

.newsletter-block input[type="text"],
.newsletter-block input[type="email"],
.newsletter-block input[type="tel"],
.newsletter-block input[type="search"],
.newsletter-block textarea {
  border: 0;
}

textarea {
  height: 150px !important;
  min-height: 150px !important;
  resize: vertical;
  padding: 10px 15px !important;
}

.title-holder {
  text-align: center;
  margin: 0 0 40px;
}

.title-holder h2 {
  margin: 0 0 5px;
}

.title-holder .subtitle {
  color: #999999;
}

.title-holder .subtitle:before {
  content: "-";
  margin: 0 5px 0 0;
}

.title-holder .subtitle:after {
  content: "-";
  margin: 0 0 0 5px;
}

.socials {
  display: table;
  height: 100%;
  width: 100%;
}

.socials center {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.socials li {
  display: inline-block;
  vertical-align: top;
  padding: 0 5px;
}

.socials social-button {
  width: 35px;
  height: 35px;
  background: #fff;
  color: #f64b4b;
  border-radius: 50%;
  display: block;
  text-align: center;
  padding: 2px;
  transition: background 0.3s linear, color 0.3s linear;
  -webkit-transition: background 0.3s linear, color 0.3s linear;
}

.socials social-button:hover {
  background: #f64b4b;
  color: #fff;
}

.socials .fab, .socials .fas {
  line-height: 2;
}

.go-top {
  width: 50px;
  height: 50px;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #f64b4b;
  border-radius: 3px;
  color: #fff;
  border: 0;
}

.go-top:before {
  width: 13px;
  height: 13px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  content: "";
  border-style: solid;
  border-color: #fff;
  border-width: 1px 1px 0 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -3px 0 0 -6px;
}

.go-top:hover {
  background: #f41a1a;
}

.pagination > li.active a {
  background: #f64b4b;
  border-color: #f64b4b;
}

.pagination > li.active a:hover {
  background: #f64b4b;
  border-color: #f64b4b;
}

.pagination > li > a {
  color: #f64b4b;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  padding: 6px 15px 12px;
}

.pagination > li > a:hover {
  background: #f64b4b;
  color: #fff;
  border-color: #f64b4b;
}

.pagetitle {
  margin: 80px 0 0;
  background: #f64b4b;
  color: #fff;
  padding: 50px 0;
}

.pagetitle .title-holder {
  margin: 0;
}

.pagetitle .subtitle {
  color: #e6e6e6;
}

#header {
  padding: 5px 0;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
  background: #fff;
  box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.5s linear;
}

#MobNav{
  display: none;
}

#NavDropdowns{
  display: flex;
  flex-direction: row;
}

#hamburger{
  display: none;
}

#header .navbar {
  padding: 0;
  justify-content: space-between;
  background: none !important;
  flex-wrap: wrap;
}

#header .navbar-brand {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #f64b4b;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0;
  font-size: 40px;
  line-height: 2.5;
}

#header .navbar-collapse {
  flex-grow: 0;
}

#header .navbar-collapse .navbar-nav {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

#header .navbar-collapse a:last-child {
  padding-right: 20;
}

#header .navbar-collapse a {
  color: #333;
  position: relative;
}

#header .navbar-collapse a:hover,
#header .navbar-collapse a.active {
  background: none;
  color: #f64b4b;
}

#header .navbar-collapse a:hover:before,
#header .navbar-collapse a.active:before {
  right: 7px;
  left: 8px;
  opacity: 1;
}

/* #header .navbar-collapse a:before {
  content: "";
  background: #f64b4b;
  height: 1px;
  position: absolute;
  bottom: 7px;
  left: -20px;
  right: 100%;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  opacity: 0;
} */

#footer {
  background: #333;
  padding: 50px 0;
  color: #fff;
  text-align: center;
}

#footer .copyright {
  margin: 0 0 15px;
  border-top: #999999 solid 1px;
  padding-top: 20px;
  font-size: 16px;
  text-align: left;
}

.fab:hover {
  color: white;
  background-color: #f64b4b;
  transform: scale(1.15);
}

.fas:hover {
  color: white;
  background-color: #f64b4b;
  transform: scale(1.15);
}

.fab, .fas{
  height: 50px;
  width: 50px;
  font-size: 25px;
  border-radius: 100px;
  background-color: white;
  margin: 30px 0px;
  color: #f64b4b;
}

.gt-logo{
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
}

.block {
  padding: 200px 0;
  border-bottom: 1px solid #e6e6e6;
}

.hero-block {
  padding: 250px 50px 100px 50px ;
  background-color: #f3f3f3;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 20px;
}

.car-img{
  border-radius: 20px;
  height: 200px;
}

.hero-block-p{
  flex: 75;
  font-size: 70px;
  text-align: center;
}

.hero-content{
  width: 80%;
  text-align: center;
}

.domain-carousel{
  border-radius: 20px;
  flex: 25;
}

.hero-block .carousel-item:before {
  content: "";
  background: rgba(0, 0, 0, 0.257);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 20px;
}

.hero-block .carousel-caption p{
  font-size: 8px;
  font-weight: 100;
}

.GT-benefits-container {
  padding: 100px 50px 30px 50px ;
  background-color: #ffffff;
}

.GT-benefits {
  padding: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items:center;
}



.benefits-p{
  text-align: center;
}

.benefits-item{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width:20%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  padding: 10px;
  height: 250px;
  font-size: large;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.benefits-item:hover{
  transform: scale(1.05);
}

.benefits-item div{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-block .progress-block {
  margin: 0 0 25px;
}

.about-block .progress-block h4 {
  text-transform: uppercase;
  margin: 0 0 5px;
  font-size: 16px;
}

.about-block .progress-bar {
  background-color: #f64b4b;
  -webkit-transition: width 0.3s linear;
  transition: width 0.3s linear;
}

.services-block .icon {
  width: 60px;
  height: 60px;
  font-size: 26px;
  color: #f64b4b;
  margin: 0 0 20px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  text-align: center;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
}

.services-block .icon .fas {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  line-height: 2;
}

.services-block .row {
  margin: 0 -15px 50px;
}

.services-block .row:last-child {
  margin: 0 -15px;
}

.services-block .holder {
  transition: all 0.3s linear;
}

.services-block .holder:hover {
  margin-top: -5px;
}

.services-block .holder:hover .icon {
  box-shadow: inset 0 0 0 30px #f64b4b;
  color: #fff;
  font-size: 18px;
}

.works-block .portfoliolist > div {
  padding: 15px;
  overflow: hidden;
}

.works-block .portfoliolist > div img {
  position: relative;
  top: 0;
  -webkit-transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.works-block .portfoliolist > div .label {
  position: absolute;
  height: 60px;
  bottom: -10px;
  left: 0;
  right: 0;
  vertical-align: middle;
  -webkit-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  background: rgba(0, 0, 0, 0.8);
  padding: 10px;
  color: #fff;
}

.works-block .portfoliolist > div h3 {
  margin: 0 0 5px;
  color: #f64b4b;
  font-size: 16px;
}

.works-block .portfoliolist > div p {
  margin: 0;
  font-size: 12px;
}

.works-block .portfoliolist > div:hover .label {
  bottom: 0;
}

.works-block .portfoliolist > div:hover img {
  transform: scale(1.08);
}

.works-block .portfoliolist .portfolio-wrapper {
  overflow: hidden;
  position: relative !important;
  background: #666;
  cursor: pointer;
  height: auto;
  width: 100%;
}

.works-block .portfoliolist .label {
  font-size: 100%;
}

.works-block .pagination {
  justify-content: center;
  margin: 25px 0 0;
}

.works-block .pagination .page-item.active .page-link {
  background: #f64b4b;
  border-color: #f64b4b;
}

.works-block .pagination .page-item .page-link {
  padding: 8px 15px 10px;
}

.teams-block{
  background-color: #f6f6f6;
}

.teams-block div[class*="col"] {
  margin: 0 0 50px;
}

.teams-block .row:last-child {
  margin: 0 -15px;
}

.teams-block [class*="col-"]:hover .overlay {
  top: 0;
  opacity: 1;
}

.teams-block .image {
  margin: 0 0 20px;
  position: relative;
  overflow: hidden;
}

.teams-block .image img {
  width: 100%;
  height: auto;
}

.teams-block .image .overlay {
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.3s linear;
  -webkit-transition: all 0.2s linear;
  vertical-align: middle;
  background: rgba(246, 75, 75, 0.9);
  opacity: 0;
}

.teams-block h3 {
  margin: 0 0 5px;
}

.teams-block .designation {
  display: block;
  font-style: italic;
  margin: 0 0 20px;
}

.testimonial-block{
  display: flex;
  align-items: center;
  justify-content: center;
  color: 'black';
} 


.testimonials-block {
  padding: 90px 0 70px;
  background-position: 50% 0;
  background-size: cover;
  background-attachment: fixed;
  /* background-image: url("assets/images/bg-testimonial.jpg"); */
  position: relative;
  color: #ffffff;
}

.testimonials-block:before {
  content: "";
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.testimonials-block .container-fluid {
  position: relative;
  z-index: 1;
  margin: 0 auto;
  max-width: 900px;
}

.testimonials-block .subtitle {
  color: #cccccc;
}

.testimonials-block blockquote {
  border: 0;
  text-align: center;
  margin: 0;
  padding: 0 0 10%;
}

.testimonials-block cite {
  font-style: normal;
  padding: 15px 0 0;
  display: block;
}

.testimonials-block cite span {
  display: block;
}

.testimonials-block .name {
  margin: 0;
  text-transform: uppercase;
}

.testimonials-block .name:before {
  content: "-";
  margin: 0 5px 0 0;
}

.testimonials-block .designation {
  font-size: 14px;
}

.testimonials-block .carousel-indicators button {
  border: 0;
}

.testimonials-block .carousel-indicators .active {
  background: #f64b4b;
}

.pricing-block .row {
  align-items: center;
  margin: 0;
}

.pricing-block .row > div {
  text-align: center;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.pricing-block .heading {
  background: #e6e6e6;
  padding: 15px 60px;
}

.pricing-block .content .list-group-item {
  padding: 10px 20px;
  border: 0;
  background: #f7f7f7;
}

.pricing-block .content .list-group-item:nth-child(2n-1) {
  background: #ededed;
}

.pricing-block .btn-holder {
  padding: 30px 20px;
}

.pricing-block div[class*="col"]:nth-child(2) {
  box-shadow: 0 0 40px 5px #e0e0e0;
  position: relative;
}

.pricing-block div[class*="col"]:nth-child(2) .heading {
  padding: 30px 20px;
  background: #f64b4b;
  color: #fff;
}

.pricing-block div[class*="col"]:nth-child(2):before {
  content: "Popular";
  text-transform: uppercase;
  position: absolute;
  top: 40px;
  left: 40px;
  background: #f76363;
  color: #fff;
  padding: 5px 10px;
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  font-size: 12px;
  text-transform: uppercase;
  width: 150px;
}

.pricing-block h3 {
  text-transform: uppercase;
  margin: 0;
}

.pricing-block .price {
  font-size: 35px;
  line-height: 45px;
}

.blog-block {
  background: #f2f2f2;
}

.blog-block.content-block .holder:hover {
  box-shadow: none;
}

.blog-block.content-block .holder:hover .image img {
  transform: none;
}

.blog-block.content-block .comment-block ul {
  margin: 0;
}

.blog-block.content-block .comment-block li {
  padding: 0;
}

.blog-block.content-block .comment-block li:before {
  display: none;
}

.blog-block .row {
  margin-bottom: 50px;
}

.blog-block .row:last-child {
  margin-bottom: 0;
}

.blog-block .holder {
  background: #fff;
  padding: 15px;
  transition: box-shadow 0.3s linear;
}

.blog-block .holder:hover {
  box-shadow: 0 0 30px 0 #d9d9d9;
}

.blog-block .card {
  border: 0;
}

.blog-block .card-body {
  padding: 0;
}

.blog-block img {
  margin: 0 0 15px;
}

.blog-block time {
  display: block;
  margin: 0 0 10px;
}

.blog-block .card-title {
  margin: 0 0 10px;
  color: #f64b4b;
  font-size: 22px;
}

.blog-block .card-title a:hover {
  color: #d10a0a;
}

.blog-block .meta {
  overflow: hidden;
  margin: 0 0 10px;
}

.blog-block .meta time {
  float: left;
  padding: 0 10px 0 0;
}

.blog-block .meta .category {
  float: right;
  padding: 0 0 0 10px;
}

.blog-block .meta .category a {
  color: #333;
}

.blog-block .meta .category a:hover {
  color: #f64b4b;
}

.contact-block {
  position: relative;
}

.contact-block .row {
  margin: 0 0 25px;
}

.contact-block .btn-holder {
  text-align: center;
}

.contact-block .contact-form {
  margin: 0 0 60px;
}

.contact-block iframe {
  border: 0;
  width: 100%;
  height: 4 50px;
}

.contact-block .contact-info {
  padding: 70px 40px;
  position: relative;
  z-index: 1;
  font-size: 18px;
  border-radius: 5px;
}

.contact-block .contact-info ul {
  text-align: center;
}

.contact-block .contact-info li {
  display: inline-block;
  vertical-align: top;
  margin: 0 5% 0 5%;
}

.contact-block .contact-info .fas {
  display: block;
  font-size: 40px;
  margin: 0 0 15px;
  color: #f64b4b;
}

@media only screen and (min-width: 1025px) {
  a[href^="tel"],
  a[href^="skype"],
  a[href^="sms"] {
    cursor: default;
    pointer-events: none;
  }
}

@media only screen and (max-width: 1023px) {
  .container-fluid{
    padding-top: 100px;
  }
  body {
    font-size: 15px;
    line-height: 24px;
  }

  h1 {
    font-size: 34px;
    margin: 0 0 15px;
  }

  h2 {
    font-size: 26px;
    margin: 0 0 15px;
  }

  .btn.btn-primary {
    padding: 9px 35px;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="search"],
  textarea {
    padding: 5px 10px;
    font-size: 15px;
  }

  #header {
    padding: 10px 0;
  }

  .e-caret-hide{
    display: none;
  }

  #hamburger{
    display: block;
  }

  #header .navbar{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  #MobNav{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 10px;
  }

  .mobnav-container{
    display: flex;
    flex-direction: row;
    font-size: 10px;
  }

   .mobnav-div{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px
  }

  #NavDropdowns{
    display: none;
  }

  #header .navbar-collapse .navbar-nav {
    float: none;
  }

  #header .navbar-collapse .nav-item {
    float: none;
    display: block;
    padding: 0px 0;
    background-color: #f64b4b;
    margin: 0px 2px;
  }

  #header .navbar-collapse .nav-item a {
    color: white;
  }

  #header .navbar-collapse .nav-item .dropdown-item {
    color: #333;
  }

  #header .navbar-brand {
    font-size: 40px;
    line-height: 3;
  }

  .hero-block .carousel-caption {
    left: 10%;
    right: 10%;
  }

  .hero-block .carousel-control {
    width: 5%;
  }

  .hero-block p {
    margin: 0 0 25px;
  }

  .GT-benefits {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:center;
  }

  .benefits-item{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width:200%;
    margin-bottom: 20px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    padding: 10px;
    height: 100%;
    font-size: large;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }
  
  .benefits-item:hover{
    transform: scale(1.05);
  }
  
  .benefits-item div{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .hero-block {
    padding: 200px 50px 30px 50px ;
    background-color: #f3f3f3;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  
  .hero-block-p {
    flex: 75;
    font-size: 45px;
    line-height: 50px;
    text-align: center;
    padding-top: 36px;
  }

  .carousel-caption p{
    display: none;
  }

  .works-block .portfoliolist > div .label {
    height: 50px;
  }
}

@media only screen and (max-width: 767px) {

  .container-fluid{
    padding-top: 80px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 20px;
  }

  .title-holder {
    margin: 0 0 20px;
  }

  .go-top {
    width: 40px;
    height: 40px;
  }

  .go-top:before {
    width: 11px;
    height: 11px;
  }

  .pagetitle {
    padding: 30px 0;
    margin: 56px 0 0;
  }

  #header .navbar-brand {
    font-size: 26px;
    line-height: 4;
  }

  #header .navbar{
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .mobnav-container{
    display: flex;
    flex-direction: column;
    font-size: 10px;
  }

  .e-caret-hide{
    display: none;
  }

  #hamburger{
    display: block;
  }

  #MobNav{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 10px;
  }

  #NavDropdowns{
    display: none;
  }

  #header .navbar-collapse .navbar-nav {
    margin: 0;
  }

  .block {
    padding: 120px 0 10px;
  }

  .hero-block .carousel-caption {
    left: 10%;
    right: 10%;
  }

  .hero-block .carousel-control {
    width: 15%;
  }

  .hero-content{
    width: 100%;
    text-align: center;
  }

  .about-block .image {
    padding-bottom: 20px;
  }

  .about-block .features [class*="col-"] {
    padding-bottom: 30px;
  }

  .about-block .progress-block {
    padding-top: 5px;
  }

  .services-block {
    padding: 100px 0 25px;
  }
  
  .services-block .row {
    text-align: center;
    margin-bottom: 0;
  }

  .services-block .holder:hover {
    margin: 0;
  }

  .works-block {
    padding: 40px 0 20px;
  }

  .works-block .portfoliolist > div {
    padding: 0 10px 20px;
  }

  .teams-block {
    text-align: center;
  }

  .teams-block .row {
    margin-bottom: 0;
  }

  .teams-block [class*="col-"] {
    padding-bottom: 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .teams-block .image {
    max-width: 350px;
    margin: 0 auto 20px;
  }

  .teams-block .designation {
    margin: 0;
  }

  .testimonials-block {
    padding: 50px 0 30px;
  }

  .testimonials-block blockquote {
    font-size: 15px;
  }

  .testimonials-block blockquote p {
    margin: 0;
  }

  .pricing-block {
    padding: 40px 0 20px;
  }

  .blog-block {
    padding: 40px 0 20px;
  }

  .blog-block [class*="col-"] {
    padding-bottom: 20px;
  }

  .blog-block .row {
    margin-bottom: 0;
  }

  .contact-block {
    padding: 100px 0 20px;
  }

  .contact-block [class*="col-"] {
    padding-bottom: 10px;
  }

  .contact-block iframe {
    height: 300px;
  }

  .contact-block .contact-info {
    padding: 10px 0 0;
    font-size: 15px;
  }
  
  .contact-block .contact-info ul{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .contact-block .contact-info li {
    display: block;
    padding: 10px 0 ;
  }

  .contact-block .contact-info .fas {
    font-size: 26px;
    margin: 0;
  }
}
