#dropdown-basic, #basic-navbar-nav #nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white !important;
  border: none;
  font-weight: 700;
  color: #333;
}

.dropdown-menu a:hover,
.dropdown-menu a::before {
  background: none;
  color: #f64b4b;
}

.e-caret-hide::after {
  display: none !important; 
}
.e-caret-hide{
  background-color: white !important;
  color: white !important;
}

#hambutton{
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}

#h3 {
  padding-left: 270px;
  padding-top: 5px;
}

#title-holder {
  padding-top: 40px;
}

#service-link {
  color: black;
}

.genie-image {
  height: 250px; 
  object-fit:cover;
  object-position: 25% 25%; 
  border-bottom: 0.5px solid gray;
}

#slide-image {
  height: 750px; 
  object-fit:cover;
  object-position: 25% 25%; 
  border-bottom: 0.5px solid gray;
}

.h3 {
  padding-top: 50px;
}

#social-button {
  color:#f64b4b;
  font-weight: bolder;
}

#top-genie-image {
  width: 250px; 
  height: 250px; 
  object-fit:cover;
  object-position: 25% 25%; 
  border-radius: 25px;
}

.genie-card{
  background-color: white;
  padding: 15px;
  height: 45rem;
  border-radius: 20px;
  text-align: center;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.content h3 {
  font-weight: 700;
}

.domain-title {
  color: white;
}

.booking-container {
  padding-bottom: 40px;
}

#option1, #option2 {
  border-color: black;
}

.booking-container {
  padding-bottom: 40px;
}

#option1, #option2 {
  border-color: black
}

.card{
  cursor: pointer;
}

.pblock{
  padding-bottom: 100px;
}

.cb{
  display: flex;
  flex-direction: row;
  background-color: rgba(0, 0, 0, 0.24);
  padding-top: 250px;
  padding-bottom: 50px;
}

.contact-block-info{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  padding-bottom: 100px;
}

.cb-section{
  width: 45%;
  border-radius: 20px;
  background-color: white;
  box-shadow: 10px 10px 10px rgb(95, 95, 95);
}

.contact-block-info span{
  color: white;
  background-color: #f64b4b;
  width: 100%;
  margin-bottom: 50px;
  text-align: center;
  padding: 40px 0px 30px 0px;
  border-radius: 20px 20px 0px 0px;
}

.cb-title{
  background-color: darkslategray;
  color: white;
  width: 100%;
  border-radius: 0px 0px 5px 5px;
}

.custom-datepicker {
  border-radius: 10px;
}

#bookingdetails{
    padding: 50px 200px 20px 200px;
    background-color: #f64b4b;
    font-size: 18px;
    font-weight: bolder;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}


#genie-services{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#genieService{
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  box-shadow: #f64b4b85 3px 1px 10px 0px, rgba(255, 255, 255, 0.8) 0px 0px 0px 10px;
}

.bp-container{
  display: flex;
  flex-direction: row;
}

#bd{
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 767px) {
  .bp-container{
    display: flex;
    flex-direction: column;
  }

  .cb-section{
    width: 95%;
    border-radius: 20px;
    background-color: white;
    box-shadow: 10px 10px 10px rgb(95, 95, 95);
    margin: 20px 0px 20px 10px;
  }

  #bookingdetails{
    padding-top: 10rem;
    width: 100%;
    background-color: #f64b4b;
    font-size: 18px;
    font-weight: bolder;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  #bookingdetails img{
    max-width: none;
    margin-bottom: 20px;
  }

  #basic-navbar-nav #nav-item{
    width: 0px !important;
  }
}

